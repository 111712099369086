<template>
	<el-container style="height: 100%;position: absolute;left: 0;top: 0;bottom: 0;right: 0; overflow: hidden;">
		<div class="left-aside" :style="navBar.isCollapse?'width: 0;':'width: 160px;'">
			<div class="logo-title">
				<!-- <img src="../assets/images/logo.png" alt=""> -->
				<span>{{$conf.logo}}</span>
			</div>
			<!-- 一级菜单侧边布局 -->
			<el-menu :default-active="navIndex" class="el-menu-vertical-demo" background-color="#20222a"
				@select="handleSelect" text-color="#b9b9bc">
				<template v-for="(item,index) in navBar.list">
					<el-menu-item :index="index|numToString" :key="index">
						<i :class="item.icon" class="nav-icon"></i>
						<span slot="title">{{item.name}}</span>
					</el-menu-item>
				</template>
			</el-menu>
			<!-- <el-submenu :index="index|numToString" v-if="item.submenu.length > 0">
						<template slot="title">
							<i :class="item.icon"></i>
							<span>{{item.name}}</span>
						</template>
						<template v-for="(two,twoIndex) in item.submenu">
							<el-submenu :index="index+'-'+twoIndex" v-if="two.submenu.length > 0">
								<template slot="title">{{two.name}}</template>
								<el-menu-item :index="index+'-'+twoIndex+'-'+thirdIndex"
									v-for="(third,thirdIndex) in two.submenu">{{third.name}}</el-menu-item>
							</el-submenu>
							<el-menu-item :index="index+'-'+twoIndex" v-else>{{two.name}}</el-menu-item>
						</template>
					</el-submenu> -->
		</div>
		<el-container class="right-aside bg-light">
			<el-header class="d-flex align-items-center bg-white header-box" :height="'50px'">
				<!-- 面包屑导航 -->
				<div class="d-flex align-items-center">
					<i :class="navBar.isCollapse ? 'el-icon-s-unfold': 'el-icon-s-fold'"
						class="mr-3 f-20 text-muted cursor-pointer" @click="handleIsCollapse"></i>
					<i class="el-icon-refresh-right mr-3 f-20 text-muted cursor-pointer" @click="handleRefresh"></i>
					<el-breadcrumb v-if="navBar.breadCrumbList.length > 0" separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>
							<i :class="navBar.breadCrumbList[0].icon"></i>
							{{navBar.breadCrumbList[0].title}}
						</el-breadcrumb-item>
						<el-breadcrumb-item v-if="navBar.breadCrumbList.length > 1">
							{{navBar.breadCrumbList[1].title}}
						</el-breadcrumb-item>
						<el-breadcrumb-item v-if="navBar.breadCrumbList.length > 2">
							{{navBar.breadCrumbList[2].title}}
						</el-breadcrumb-item>
					</el-breadcrumb>
				</div>

				<!-- 	<div class="h6 mb-0 ml-auto mr-2 cursor-pointer" @click="renew">
					
					{{user.user_name ? user.user_name : ''}}
				</div> -->

				<div class="h6 mb-0 ml-auto mr-3 d-flex align-items-center cursor-pointer">
					<div class="mr-3 align-items-center">
						<el-select @change="hanledSearch()" remote filterable size="mini" v-model="selectSearch"
							placeholder="搜索...">
							<template v-for="(item,index) in navBar.list">
								<el-option-group v-if="item.submenu.length > 0" :label="item.name">
									<template v-for="(two,twoIndex) in item.submenu">
										<el-option-group v-if="two.submenu.length > 0" :label="two.name">
											<el-option v-for="three in two.submenu" :label="three.name"
												:value="three.index"></el-option>
										</el-option-group>
										<el-option v-else :label="two.name" :value="two.index"></el-option>
									</template>
								</el-option-group>
								<el-option v-else :label="item.name" :value="item.index"></el-option>
							</template>
						</el-select>
					</div>
					<!-- 全屏 -->
					<el-tooltip class="item mr-3" effect="dark" :content="isScreenFull?'退出全屏':'全屏'"
						placement="top-start">
						<i @click="screenFullBtn" class="el-icon-full-screen f-20"></i>
						<!-- <img src="../assets/images/screenFull.png" alt="" @click="screenFullBtn" class="screenFull"v-if="!isScreenFull"> -->
						<!-- <img src="../assets/images/exitScreenFull.png" alt="" @click="screenFullBtn" class="screenFull"v-else> -->
					</el-tooltip>
					<!-- 用户操作 -->
					<div class="d-flex align-items-center">
						<el-dropdown trigger="hover" @command="selectCommand" placement="bottom">
							<div class="f-12">{{user ? user.user_name : ''}}</div>
							<el-dropdown-menu size="small" slot="dropdown">
								<el-dropdown-item class="f-12" command="user"><i class="el-icon-user"></i>个人中心
								</el-dropdown-item>
								<el-dropdown-item class="f-12" command="logout"><i class="el-icon-circle-close"></i>退出登录
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>

				<!-- 	<a class="mb-0 ml-4 mr-3" @click="logout" style="cursor: pointer;">
					<i class="el-icon-error" style="line-height: 50px;"></i>退出
				</a> -->
			</el-header>
			<!-- 侧边布局 -->
			<el-container>
				<el-aside :style="navBar.isCollapse?'width: 0;transition: all .5s;':'width: 120px;transition: all .5s;'" v-if="navBar.list[navIndex].submenu.length > 0">
					<el-menu :default-active="menuIndex" class="el-menu-vertical" background-color="#ffffff"
						@select="handleSelect" text-color="#333333">
						<template v-for="(two,twoIndex) in navBar.list[navIndex].submenu">
							<el-submenu :default-active="submenuIndex" :index="navIndex+'-'+twoIndex" v-if="two.submenu.length > 0">
								<template slot="title">{{two.name}}</template>
								<el-menu-item :index="navIndex+'-'+twoIndex+'-'+thirdIndex"
									v-for="(third,thirdIndex) in two.submenu">{{third.name}}</el-menu-item>
							</el-submenu>
							<el-menu-item :index="navIndex+'-'+twoIndex" v-else>{{two.name}}</el-menu-item>
						</template>
					</el-menu>
				</el-aside>
				
				<el-main class="p-2" style="position: relative;height: calc(100vh - 50px);padding-bottom: 120px;"
					v-loading="loading">
					<!-- 标签栏 -->
					<tags></tags>
					<!-- 主内容 -->
					<router-view></router-view>
					<el-backtop target=".el-main" :bottom="100">
						<div
							style="height: 100%; width: 100%; background-color: #f2f5f6; box-shadow: 0 0 6px rgba(0,0,0, .12); text-align: center;  line-height: 40px; color: #1989fa;">
							<i class="el-icon-top"></i>
						</div>
					</el-backtop>
				</el-main>
			</el-container>
		</el-container>
	</el-container>
</template>

<script>
	import common from "@/common/mixins/common.js"
	import tags from "@/components/tags/tags.vue";
	import screenfull from 'screenfull'
	import {
		mapState
	} from 'vuex'

	export default {
		mixins: [common],
		components: {
			tags
		},
		provide() {
			return {
				layout: this
			}
		},
		data() {
			return {
				navIndex: 0,
				menuIndex: 0,
				submenuIndex: 0,
				selectSearch: '',
				loading: false,
				fullWidth: document.documentElement.clientWidth,
				isScreenFull: false
			}
		},

		mounted() {
			window.onresize = () => {
				return (() => {
					// 判断是否在全屏
					if (!this.checkFull()) {
						// 退出全屏后要执行的动作
						this.isScreenFull = false;
					}
					window.fullWidth = document.documentElement.clientWidth
					this.fullWidth = window.fullWidth
				})()
			}

		},
		created() {
			// 获取面包屑导航
			this.getRouterBran()
		},

		watch: {
			'$route'(to, from) {
				this.setActive(to.name)
				this.getRouterBran()
			},
			/* 监听屏幕宽度*/
			fullWidth(val) {
				if (val < 1080 && val > 0) {
					this.navBar.isCollapse = true
					window.localStorage.setItem('navBar', JSON.stringify(this.navBar))
				} else {
					this.navBar.isCollapse = false
					window.localStorage.setItem('navBar', JSON.stringify(this.navBar))
				}
			}
		},
		computed: {
			...mapState({
				user: state => state.user.user.user,
				navBar: state => state.menu.navBar
			}),
		},
		methods: {
			// 是否全屏
			screenFullBtn() {
				screenfull.toggle()
				this.isScreenFull = !this.isScreenFull
			},

			//检测是否在全屏
			checkFull() {
				//判断浏览器是否处于全屏状态 （需要考虑兼容问题）
				//火狐浏览器
				var isFull = document.mozFullScreen ||
					document.fullScreen ||
					//谷歌浏览器及Webkit内核浏览器
					document.webkitIsFullScreen ||
					document.webkitRequestFullScreen ||
					document.mozRequestFullScreen ||
					document.msFullscreenEnabled
				if (isFull === undefined) {
					isFull = false
				}
				return isFull;

			},

			// 显示loading
			showLoading() {
				this.loading = true
			},
			// 隐藏loading
			hideLoading() {
				this.loading = false
			},
			getRouterBran() {
				// 更新面包屑
				let active = this.navBar.active
				let breadCrumbList = []
				if (active != '') {
					let nav = active.split("-");
					let navLen = nav.length;
					this.navIndex = nav[0];
					if (navLen > 0) {
						breadCrumbList.push({
							title: this.navBar.list[nav[0]].name,
							icon: this.navBar.list[nav[0]].icon,
							path: this.navBar.list[nav[0]].index
						});
						if (navLen > 1) {
							this.menuIndex = nav[1];
							breadCrumbList.push({
								title: this.navBar.list[nav[0]].submenu[nav[1]].name,
								path: this.navBar.list[nav[0]].submenu[nav[1]].index
							});
							if (navLen > 2) {
								this.submenuIndex = nav[2];
								breadCrumbList.push({
									title: this.navBar.list[nav[0]].submenu[nav[1]].submenu[nav[2]].name,
									path: this.navBar.list[nav[0]].submenu[nav[1]].submenu[nav[2]].index
								});
							}
						}
					}
				}
				this.navBar.breadCrumbList = breadCrumbList;
				window.localStorage.setItem('navBar', JSON.stringify(this.navBar))
			},
			setActive(name) {
				let active = '';
				for (let i = 0; i < this.navBar.list.length; i++) {
					let two = this.navBar.list[i].submenu;
					if (name == this.navBar.list[i].index) {
						active = i + '';
						if (two.length > 0) {
							for (let t = 0; t < two.length; t++) {
								if (two[t].index == name) {
									active += '-' + t;
									let three = two[t].submenu;
									if (three.length > 0) {
										for (let r = 0; r < three.length; r++) {
											if (three[r].index == name) {
												active += '-' + r;
											}
										}
									}
								}
							}
						}
					} else {
						// 判断二级是否存在
						if (two.length > 0) {
							for (let t = 0; t < two.length; t++) {
								let three = two[t].submenu;
								if (two[t].index == name) {
									active += i + '-' + t;
									if (three.length > 0) {
										for (let r = 0; r < three.length; r++) {
											if (three[r].index == name) {
												active += '-' + r;
											}
										}
									}
								} else {
									if (three.length > 0) {
										for (let r = 0; r < three.length; r++) {
											if (three[r].index == name) {
												active += i + '-' + t + '-' + r;
											}
										}
									}
								}
							}
						}
					}
				}
				this.navBar.active = active
				window.localStorage.setItem('navBar', JSON.stringify(this.navBar))
			},
			
			handleSelect(key, keyPath) {
				this.navBar.active = key;
				window.localStorage.setItem('navBar', JSON.stringify(this.navBar))

				let nav = key.split("-");
				let navLen = nav.length;
				

				// // 默认选中跳转到当前激活
				if (navLen > 0) {
					let name;
					this.navIndex = nav[0];
					if (navLen == 1) {
						name = this.navBar.list[nav[0]].index
					} else if (navLen == 2) {
						this.menuIndex = nav[1];
						name = this.navBar.list[nav[0]].submenu[nav[1]].index
					} else if (navLen == 3) {
						this.submenuIndex = nav[2];
						name = this.navBar.list[nav[0]].submenu[nav[1]].submenu[nav[2]].index
					}
					this.$router.push({
						name: name
					})
				}
				console.log('navIndex,menuIndex', this.navIndex, this.menuIndex, this.submenuIndex)
			},
			
			handleIsCollapse() {
				let isCollapse = !this.navBar.isCollapse
				this.navBar.isCollapse = isCollapse;
				window.localStorage.setItem('navBar', JSON.stringify(this.navBar))
			},
			
			handleRefresh() {
				this.$router.go(0)
			},

			//顶部下拉菜单选择操作
			selectCommand(val) {
				if (val === 'user') {
					this.$router.push({
						name: "store.user/renew"
					})
				} else if (val === 'logout') {
					this.logout()
				}

			},
			
			hanledSearch() {
				this.$router.push({
					name: this.selectSearch
				})
			},
			
			// 退出登录
			logout() {
				this.axios({
					token: true,
					params: {
						s: 'store/passport/logout',
					},
					method: 'post',
				}).then(res => {
					this.$message('退出成功')
					// 清除状态和存储
					this.$store.commit('logout')
					// 返回到登录页
					this.$router.push({
						name: "passport/login"
					})
				}).catch(err => {
					// 清除状态和存储
					this.$store.commit('logout')
					// 返回到登录页
					this.$router.push({
						name: "login"
					})
				})
			},
		}
	}
</script>

<style lang="scss">
	.left-aside {
		box-sizing: border-box;
		// max-width: 160px;
		// min-width: 64px;
		width: 160px;
		height: 100vh;
		overflow-y: auto;
		overflow-x: hidden;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		padding-top: 50px;
		position: relative;
		transition: all .5s;

		.logo-title {
			padding-left: 5px;
			padding-right: 5px;
			color: #FFFFFF;
			font-size: 16px;
			width: 160px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			background-color: #20222a;
			position: absolute;
			top: 0;
			left: 0;
		}

		.nav-icon {
			color: #FFFFFF;
			font-size: 22px;
			margin-right: 10px;
		}

		.el-menu {
			height: 100%;
		}

		.el-menu-item {
			width: 160px;
			height: 54px;
			line-height: 42px;
			padding: 6px 5px;
			border-left: 3px solid #20222a;
		}

		.el-menu-item:hover {
			border-left: 3px solid #2d8cf0;
		}

		.el-menu-item:hover span {
			color: #FFFFFF;
			font-weight: bold;
		}

		.el-menu-item.is-active {
			color: #FFFFFF;
			border-left: 3px solid #2d8cf0;
			background-color: #2d8cf0 !important;
		}
	}

	.right-aside {
		
		.header-box {
			border-bottom: solid 1px #e6e6e6;
			z-index: 9;
		}

		.el-menu-vertical {
			width: 120px;
			height: 100%;
		}

		.el-menu-item.is-active {
			color: #333333;
			background-color: #f5f5f5 !important;
		}

		.el-submenu__title:hover,
		.el-menu-item:hover {
			background-color: #f5f5f5 !important;
		}

	}

	.screenFull {
		width: 25px;
		height: 25px;
		margin-right: 1.875rem;
	}

	.el-container {
		display: flex;
		flex-direction: row;
		flex: 1;
		/* flex-basis: auto; */
		box-sizing: border-box;
		min-width: 0;
	}

	.el-breadcrumb>span:last-child>.el-breadcrumb__inner {
		font-weight: 700;
		color: #515a6e;
	}
</style>
